import React from 'react';
import TheCosmos from './TheCosmos';

function App() {

  return (
      <TheCosmos />
  );
}

export default App;